import {
  NpmLayout,
  NpmRegionalizationProvider,
  NpmSpecialPromotionsContextProvider,
  NpmFAQContextProvider,
} from '@plurix/ecom-pages/'
import { HAS_WINDOW } from '@plurix/ecom-pages/src/constants'
import React, { useEffect } from 'react'

// feature toggle in development
import devFeatureToggle from '../devFeatureToggle.json'
import {
  account,
  salesChannels,
  salesForceIds,
  api,
  secureSubdomain,
  checkoutUrl,
  loginUrl,
  accountUrl,
} from '../store.config'
import useRestrictedMode from './hooks/restrictedMode'
import useCmsCartSimulation from './hooks/useCmsCartSimulation'
import useCmsCookiesText from './hooks/useCmsCookiesText'
import useCmsExternalMenus from './hooks/useCmsExternalMenus'
import useCmsFaq from './hooks/useCmsFaq'
import useCmsFeatureToggle from './hooks/useCmsFeatureToggle'
import useCmsFoodRestriction from './hooks/useCmsFoodRestriction'
import useCmsFooter from './hooks/useCmsFooter'
import useCmsFullCategoriesMenu from './hooks/useCmsFullCategoriesMenu'
import useCmsHome from './hooks/useCmsHome'
import useCMSLoyaltyClub from './hooks/useCMSLoyaltyClub'
import useCmsMaintenanceStore from './hooks/useCmsMaintenanceStore'
import useCmsPaymentMethods from './hooks/useCmsPaymentMethods'
import useCmsPlp from './hooks/useCmsPlp'
import useCmsRegionalizationInfos from './hooks/useCmsRegionalizationInfos'
import useCmsSocialMediaLinks from './hooks/useCmsSocialMediaLinks'
import useCmsSpecialPromotions from './hooks/useCmsSpecialPromotions'
import useCmsWhatsappOffers from './hooks/useCmsWhatsappOffers'
import { useDynamicNotification } from './hooks/useDynamicNotification'
import useCmsUtmPromotion from './hooks/useUTMPromotion'

interface LayoutProps {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  useEffect(() => {
    if (!HAS_WINDOW) {
      return
    }

    window.localStorage.setItem('storeId', api.storeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HAS_WINDOW])

  const { faqData } = useCmsFaq()
  const { featureToggle } = useCmsFeatureToggle()
  const { simulationPostalCode } = useCmsCartSimulation()

  const salesWhatsappOffers = useCmsWhatsappOffers()

  const { sections, logo } = useCmsHome()

  const { allCategories } = useCmsFullCategoriesMenu()
  const { menuFirst, menuSecond, menuPromotionalBanner, menuCouponsBanner } =
    useCmsExternalMenus()

  const socialMediaLinks = useCmsSocialMediaLinks()
  const { storeInfos } = useCmsFooter()
  const { pricesAndHours, pickupConfirmScreenText } =
    useCmsRegionalizationInfos()

  const { cookiesText } = useCmsCookiesText()
  const { loyaltyClubTexts, loyaltyClubBanner, loyaltyClubDownloadImages } =
    useCMSLoyaltyClub()

  const { plpBanners } = useCmsPlp()
  const { paymentMethods } = useCmsPaymentMethods()
  const { allPromotions } = useCmsSpecialPromotions()

  const dynamicNotification = useDynamicNotification()

  const { utmPromotionBond } = useCmsUtmPromotion()

  const { maintenanceStore } = useCmsMaintenanceStore()

  const { passKey } = useRestrictedMode()

  const { foodRestriction, storageRestriction } = useCmsFoodRestriction()

  return (
    // this provider need to be here because the hook's rules
    <NpmRegionalizationProvider
      simulationPostalCode={simulationPostalCode}
      pickupConfirmScreenText={pickupConfirmScreenText ?? ''}
    >
      <NpmSpecialPromotionsContextProvider allPromotions={allPromotions}>
        <NpmFAQContextProvider faqData={faqData}>
          <NpmLayout
            // store infos
            account={account}
            secureSubdomain={secureSubdomain}
            checkoutUrl={checkoutUrl}
            loginUrl={loginUrl}
            accountUrl={accountUrl}
            salesChannels={salesChannels}
            salesForceIds={salesForceIds}
            devFeatureToggle={devFeatureToggle}
            // cms
            featureToggle={featureToggle}
            restrictedMode={{ passKey }}
            footerPaymentMethods={paymentMethods}
            allCategories={allCategories}
            menuFirst={menuFirst}
            menuSecond={menuSecond}
            menuPromotionalBanner={menuPromotionalBanner}
            menuCouponsBanner={menuCouponsBanner}
            socialMediaLinks={socialMediaLinks}
            storeInfos={storeInfos}
            pricesAndHours={pricesAndHours}
            cookiesText={cookiesText}
            loyaltyClubTexts={loyaltyClubTexts}
            loyaltyClubBanner={loyaltyClubBanner}
            loyaltyClubDownloadImages={loyaltyClubDownloadImages}
            plpBanners={plpBanners}
            notificationsBar={dynamicNotification?.notification}
            downloadModal={dynamicNotification?.modalInfos}
            salesWhatsappOffers={salesWhatsappOffers}
            utmPromotionBond={utmPromotionBond}
            maintenanceStore={maintenanceStore}
            sections={sections}
            logo={logo}
            foodRestrictionSection={foodRestriction}
            storageRestrictionSection={storageRestriction}
          >
            {children}
          </NpmLayout>
        </NpmFAQContextProvider>
      </NpmSpecialPromotionsContextProvider>
    </NpmRegionalizationProvider>
  )
}
